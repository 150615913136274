import { API_ROOT } from "./global-var";

export async function fetchImageUrl(mediaId) {
    if (!mediaId) return '/default-image.jpg';

    try {
        const response = await fetch(`${API_ROOT}/media/${mediaId}`);
        if (!response.ok) {
            console.error(`Failed to fetch image with media ID: ${mediaId}`);
            return '/default-image.jpg';
        }

        const imageData = await response.json();
        return imageData.source_url || '/default-image.jpg';
    } catch (error) {
        console.error(`Error fetching image for media ID: ${mediaId}`, error);
        return '/default-image.jpg';
    }
}


