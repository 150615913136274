import React from 'react';
import './styles/styles.scss';
import { NavLink } from 'react-router-dom';


export default function Header() {
    return (
        <div className="header container">
            <div className="logo">
                <h2><NavLink to={"/"}>Makymadi.net</NavLink></h2>
            </div>

            <div className="menu">
                <ul>
                    <li><NavLink to={"/projects"}>Projects</NavLink></li>
                </ul>
            </div>
        </div>
    );
}
