import React from 'react';
import 'animate.css';
import './styles/style.scss';

export default function HomePage() {

    return (
        <div className="home-page animate__animated animate__fadeIn">
            <div className="intro">
                <div className="container">
                    <h1>
                        Hi, I'm Maky! <br />
                        <span>
                            Software Engineer mainly focused on frontend, <br />I build custom
                            WEB solutions
                        </span>
                    </h1>

                    <div className='tech-stack'>
                        <a href="https://react.dev" target="_blank"><img src="./reactjs.png" /></a>
                        <a href="https://nextjs.org/" target="_blank"><img src="/nextjs.svg" className="nextjs-icon" /></a>
                        <a href="https://getbootstrap.com/" target="_blank"><img src="./bootstrap.png" /></a>
                        <a href="https://wordpress.com" target="_blank"><img src="./wordpress.png" className='wp-icon' /></a>
                        <a href="https://ant.design/" target="_blank"><img src="./antd.png" /></a>
                        <a href="https://nestjs.com/" target="_blank"><img src="./nestjs.png" /></a>
                        <a href="https://flutter.dev/" target="_blank"><img src="./flutter.png" /></a>
                    </div>
                </div>
            </div>
        </div>
    );
}
