let urls = {
  development: "https://www.backoffice.makymadi.net/index.php/wp-json/wp/v2",
  production: "https://www.backoffice.makymadi.net/index.php/wp-json/wp/v2",
};
export const API_ROOT = urls[process.env.NODE_ENV];

export const getUser = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  if (token) {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  }
};

export const customIcon = (name, size) => {
  return <img src={`./assets/img/${name}`} width={size} alt="img" />;
};
