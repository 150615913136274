import React from 'react';
import './styles/styles.scss';


export default function Footer() {
    return (
        <div className="footer container">
            <div className="contact">
                <div className="footer-menu">
                    <ul>
                        <li>
                            <a href="https://github.com/madimakym/" target="_blank">Github</a>
                            <a href="https://www.linkedin.com/in/maky-madingou-94818570/" target="_blank">LinkedIn</a>
                            <a href="https://www.instagram.com/makymadi/" target="_blank">Instagram</a>
                        </li>
                    </ul>
                </div>
                <div className="copyright">© 2024 makymadi. All right reserved</div>
            </div>
        </div>
    );
}
